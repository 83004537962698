
let clientName = "Western Digital";
//let productName = "Western Digital";
/* Attention, il y a un clientName non-dynamique dans l'optin */

export const de = {

    /* pageTitles are the H2 title at the TOP of each page */
    pageTitles: {
        beforeStart: "Diese Aktion ist noch nicht verfügbar.",
        actionEnded: "Diese Aktion ist beendet.",
        maxReached: "Maximum an Teilnahmen erreicht",
        wrongCode: "Falscher Code",
        wrongCode2: "Falscher Beteiligungslink",
        alreadySend: "Teilnahme bereits gesendet",
        registration: "Anmeldung",
        confirmation: "Bestätigung",
        choiceSurvey: "",
        survey: "Umfrage",
        summary: "Zusammenfassung",
        maintenance: "Wartung",
        form1: "Fordern Sie jetzt Ihr Cashback an!",
        form2: "Noch einige Informationen...",
        wait: "Bitte warten Sie...",
        instructions: "Wie Sie teilnehmen können",
        pageError: "Ein Fehler ist aufgetreten",
        catalog: "Katalog",
    },

    /* sectionTitles are titles INSIDE pages */
    /* They should not be arranged by page, because they can move from one to another */
    sectionTitles: {
        wayOfRegistration: "Wählen Sie eine Anmeldemethode",
        thanksForRegistration: "Danke für Ihre Anmeldung!",
        aboutYou: "Sagen Sie uns, wer Sie sind",
        address: "Ihre Adresse",
        aboutProduct: "Über unser Produkt",
        ticketUpload: "Nachweis des Kaufs",
    },

    /* inputsTexts is a list for every label, placeholder and helpers that Action API don't give. */
    inputsTexts: {
        gtin : {
            label: "Tippen Sie einen Strichcode ein",
            placeholder: "0000000000000",
            helper: "Er besteht aus 13 Ziffern",
        },
        listEan : {
            label: "Wählen Sie einen Strichcode aus",
            placeholder: "Wählen Sie einen Strichcode aus der Liste",
            helper: " ",
        },
        dropdown : {
            placeholder: "Wählen Sie eine Antwort aus...",
            helper: " ",
            missingData: "Das Feld muss eine Antwort enthalten",
            errorLength: "Die Länge ist nicht gut",
        },
        title: {
            label: "Titel",
            placeholder: "Wie dürfen wir Sie nennen?",
            smallplaceholder: "Wie dürfen wir Sie nennen?",
            helper: " ",
            choice1: "Frau",
            choice2: "Herr",
            choice3: "Andere",
            choice4: "Ich möchte es lieber nicht sagen",
        },
        gender: {
            label: "Geschlecht",
            placeholder: "Wie definieren Sie sich selbst?",
            smallplaceholder: "Wie definieren Sie sich selbst?",
            helper: " ",
        },
        firstname: {
            label: "Vorname",
            placeholder : "Mein Vorname ist…",
            helper: " ",
        },
        lastname: {
            label: "Name",
            placeholder : "Mein Name ist…",
            helper: " ",
        },
        email : {
            label: "E-mail",
            placeholder: "nom{'@'}exemple.be",
            helper: "Ihre E-Mail muss ein {'@'} enthalten"
        },
        phone : {
            label: "Mobile",
            placeholder: "Bsp : +41012345678",
            helper: "Die Handynummer muss mit +41 beginnen",
            error_message: "Die Handynummer muss eine gültige Handynummer sein"
        },
        iban : {
            label: "IBAN",
            placeholder: "ex : BE00123000456000",
            helper: "Die Nummer muss mit dem Ländercode beginnen (CH)",
            error_message: "Die Iban muss eine gültige IBAN sein"
        },
        VATNumber: {
            label: "Numéro de TVA",
            placeholder: "Ex: BE0123123123",
            helper: "Le numéro doit commencer par le code pays (BE/LU/…)"
        },
        googleAPI:{
            label: "Bitte suchen Sie nach Ihrer vollständigen Adresse (mit Hausnummer), damit Sie das Formular bestätigen können.",
            placeholder: "Suchen...",
            ariaLabel: "Google Adresse Suchleiste. Geben Sie hier Ihre Adresse ein.",
        },
        street : {
            label: "Straße",
            labelfacture: "Rechnungsadresse",
            placeholder: "",
            helper: " "
        },
        houseNumber: {
            label: "Nummer",
            placeholder: "",
            helper: " "
        },
        boxNumber: {
            label: "Box",
            placeholder: "",
            helper: " "
        },
        postalCode: {
            label: "Postleitzahl",
            placeholder: "",
            helper: " "
        },
        city: {
            label: "Ort",
            placeholder: "",
            helper: " "
        },
        country: {
            label: "Land",
            placeholder: "Wählen Sie ein Land",
            helper: " ",
            choice1: "Schweiss"
        },
        conditions : {
            part1 : "Ich akzeptiere die",
            link: "Allgemeinen Bedingungen",
            part3: " "
        },
        serialNumber : {
            label: "",
            placeholder: "",
            helper: " "
        },
        product: {
            label: "Welches Produkt haben Sie gekauft?",
            placeholder: "Wählen Sie eine Antwort aus...",
            helper: " "
        },
        retailer: {
            label: "Wo haben Sie das Produkt gekauft?",
            placeholder: "Wählen Sie eine Antwort aus...",
            helper: " "
        },
        freeRetailer: {
            label: `In welchem Geschäft haben Sie Ihre Produkte gekauft?`,
            placeholder: "…",
            helper: " "
        },
        freeInput: {
            label: ``,
            placeholder: "",
            helper: "",
            error_empty: "Bitte füllen Sie dieses Feld aus",
            error_wrongValue: "Der Wert ist falsch",
        },
        datePurchase : {
            label: `Wann haben Sie Ihre Produkte gekauft`,
            placeholder: "jj/mm/aaaa",
            helper: `<strong>ACHTUNG:</strong> Bitte verwenden Sie den Kalender, um das Datum einzugeben.`,
        },
        uploads: {
            ticket: {
                label: "Klicken Sie hier oder reichen Sie Ihr Ticket ein."
            },
            dropzone1: {
                label: "Klicken Sie hier oder legen Sie ein Foto des Barcodes ab.",
            },
        },
        optin : {
            part1:"Ich möchte über ",
            linklabel:`${clientName}`,
            link:"https://www.westerndigital.com/de-at",
            part2:"-Aktionen auf dem Laufenden gehalten werden",
            label : "", /* Should not be visible */
            helper : "",
            complement: `ich möchte per E-Mail über zukünftige Aktionen von ${clientName}.`,
        },
        optin2 : {
            part1:"Je consens à recevoir des communications personnalisées concernant les offres, actualités et autres initiatives promotionnelles de la part de Braun et d’autres ",
            linklabel:`marques P&G`,
            link:"https://fr-be.pg.com/",
            part2:"par e-mail et sur les canaux en ligne. Je peux retirer",
            part3:"à tout moment.",
            linklabel2:`mon consentement`,
            link2:"https://preferencecenter.pg.com/fr-be/datarequests/",
            label : "", /* Should not be visible */
            helper : "",
            complement: `Je souhaite être tenu au courant par e-mails des futures actions de ${clientName}.`,
        },
        optin3 : {
            part1:"Vous avez au moins 18 ans et acceptez nos",
            linklabel:`Conditions générales.`,
            link:"https://termsandconditions.pg.com/fr-be/",
        },
        errors:{
            error_incorrect: "Ungültige Daten. Bitte überprüfen Sie dies.",
            error_email: "Die E-Mail-Adresse muss eine gültige E-Mail-Adresse sein",
            error_iban: "Die Iban muss eine gültige IBAN sein",
            mandatory: "Bitte füllen Sie dieses Feld aus",
        }
    },

    testPanel: {
        title: 'Les pages "cachées"',
        text: "Cliquez sur les boutons ci-dessous pour accéder aux pages du formulaire qui ne font pas partie du flux normal de la participation",
        pages: {
            before: "Avant le début",
            after: "Après la fin",
            maxparticip: "Max. participation",
            wrongcode: "Mauvais code",
            alreadysent: "Déjà envoyé",
            maintenance: "En maintenance",
            loading: "Chargement…",
        },
        hide: "Cachez-moi",
        show: "Montrez-moi",
    },



    /*
    ABOVE: WHAT MUST BE USED NOW
    BELOW: WHAT WAS USED BEFORE
    */

    page404: {
        title: "404",
        text: "Cette page n'existe pas.",
        back: "Retour au formulaire",
    },

    errors: {
        emptyField: "Sie müssen eine Antwort geben",
        fieldMissingValue: "Ein oder mehrere Felder haben einen falschen oder leeren Wert",
        dateInvalid: "Das Datum ist ungültig",
        dateBefore: "Das Datum darf kein zukünftiges Datum sein",
        dateOutOfPeriod: "Der Kauf muss innerhalb des für diese Aktion gültigen Datums erfolgen",
        wrongcnk: "Der CNK-Code ist nicht korrekt",
        fieldsMissing: "Die Teilnahme konnte nicht gesendet werden, da ein oder mehrere Felder nicht ausgefüllt/gültig sind",
        fieldsMissingTitle: "Leere/ungültige Felder",
        unmanaged: "Es ist ein unerwarteter Fehler aufgetreten",
    },


    maintenance: {
        line1: "Diese Website wird derzeit gewartet und kann nicht aufgerufen werden. Versuchen Sie es später noch einmal",
        line2: "Unser Team bemüht sich, die Website so schnell wie möglich wieder zugänglich zu machen.",
        thanks: "Vielen Dank für Ihr Verständnis",
    },

    macaron: {
        big: `100%`,
        small:`remboursé`,
    },

    /* Classic Footer */
    footer: {
        genterms: "Allgemeine Bedingungen",
        privacy: {
            link: "https://www.westerndigital.com/de-at/legal/privacy-statement",
            label: "Datenschutzerklärung"
        },
        cookies: {
            link: "https://www.westerndigital.com/de-at/legal/privacy-statement",
            label: "Cookie-Richtlinie",
        }
    },

    /* Special P&G Footer */
    pgFooter: {
        conditions: "Conditions de l'action",
        /* Below: They appears in the footer as long as there is a link value. */
        privacy: {
            link: "https://privacypolicy.pg.com/fr/",
            label: "Déclaration de confidentialité",
        },
        otherconditions: {
            link:"https://www.pg.com/fr_be/terms_conditions/index.shtml",
            label:"Conditions générales"
        },
        mycoords: {
            link: "https://preferencecenter.pg.com/fr-be/",
            label: "Mes données",
        },
        contact: {
            link: "https://www.pg.com/privacy/contact_us/contact_us/privacy-central-contact-interactive.html",
            label: "Contact",
        },
    },

    gdpr: {
        title: "Datenschutzhinweis",
        client: `${clientName}`,
    },

    onetrust: {
        cookie: "Gestion des cookies",
    },

    navigation: {
        doSurvey: "Auf die Umfrage antworten",
        skipSurvey: "Cashback beantragen",
        previous: "Vorherige Seite",
        next: "Nächste Seite",
        claimCashback: "Meinen Cashback beantragen",
        signup: "Ich melde mich an!",
        participate: "Teilnehmen!",
        toCustomerWebsite: {
            label: "Zu unserer Website",
            link: ""
        },
        readMoreOnWebsite: "Erfahren Sie mehr",
    },

    breadcrumbSteps: {
        info: "Wie man teilnimmt",
        registration: "Registrieren Sie sich",
        store: "Kaufen Sie ein Produkt",
        form: "Füllen Sie das Formular aus",
        survey: "Beantworten Sie einen optionalen Fragebogen",
        cashback: "Erhalten Sie Ihre Rückerstattung",
        summary: "Zusammenfassung",
    },

    formHeader: {
        bottomText: `* à la conditions…`,
        macaronText: `remboursés\u00A0!`,
        mainTitle: "Inscrivez-vous et obtenez votre cashback\u00A0!"
    },



    pageLitteracy: {
        pageError: {
            intro: "Une erreur est survenue qui empêche le formulaire de fonctionner.",
            solution1 : "Veuillez essayer de recharger le formulaire ou réessayer plus tard.",
            solution2 : `Si l'erreur persiste, prenez contact avec Promolife sur le mail <a href="mailto:infoligne{'@'}promolife.be">infoligne{'@'}promolife.be</a> ou au <a href="tel:3223520398">+32 2 352 03 98</a>`,
        },
        instructions : {
            intro: "Anweisungen zur Teilnahme wie in einer HAv1. Keine breadcrumb hier, da sonst doppelt verwendet (Icons, Texte)",
            steps: {
                info: "Informationsseite",
                register: "Wenn Sie später teilnehmen wollen, melden Sie sich jetzt an, damit Sie eine Erinnerung erhalten.",
                store: {
                    line1a: "Termin im Geschäft zwischen",
                    line1b: "und dem",
                    line2: "Kaufen Sie ein MARQUEMACHIN-Produkt.",
                    line3: "Bewahren Sie Ihren Kassenbon/Ihre Rechnung gut auf.",
                },
                form: {
                    line1: "Geben Sie Ihre vollständigen Kontaktdaten ein.",
                    line2: "Kreuzen Sie auf dem Kaufbeleg für Ihre MARQUEMACHIN-Produkte (Rechnung / Kassenzettel) das Datum, die gekauften Produkte und den Preis an.",
                    line3: "Fotografieren oder scannen Sie dann den gesamten Kaufbeleg.",
                    line4: "Laden Sie das Foto oder den Scan hoch.",
                },
                survey: "Nehmen Sie an einer optionalen Umfrage teil und erhalten Sie einen Rabattcoupon.",
                cashback: "Das war's. Nach der Bestätigung erhalten Sie Ihr Geld innerhalb von 6 Wochen zurück.",
            }
        },
        maxReached : "Diese Aktion ist beendet, da sie die maximale Anzahl an Teilnahmen erreicht hat.",
        wrongCode: {
            text: `Der gelieferte Schlüssel ist falsch. Sie müssen den Link verwenden, den Ihnen ${clientName} geschickt hat.`,
            textregistration: "Vielleicht möchten Sie sich aber auch für die Aktionu anmelden?",
            errorWithReparticipation: `Es ist ein Fehler mit Ihrem Teilnahmelink aufgetreten.<br> Bitte kontaktieren Sie unsere technischen Teams, indem Sie eine E-Mail an <a href="mailto:infoligne{'@'}promolife.be">infoligne{'@'}promolife.be</a> senden und die untenstehenden Informationen&nbsp; anfügen:`
        },
        alreadySend: "Sie haben Ihre Teilnahme bereits abgeschickt.",
        registration: {
            byRegistering: "Indem ich mich für diese Aktion anmelde, stimme ich ",
            privacyNotice: "die Datenschutzrichtlinie",
            claimMail: "Anmeldung per E-Mail",
            claimWhatsapp: "Anmelden per Whatsapp",
            claimSms: "Per SMS anmelden",
            or: "Oder",
            whatsappTitle: "Klicken Sie auf die Whatsapp-Schaltfläche",
            whatsappInfo: {
                part1: `Es öffnet sich ein Fenster, in dem Sie aufgefordert werden, das Wort zu senden`,
                part2: `aus Ihrem Whatsapp.`
            },
        },
        confirmRegistration: {
            smsLine1: "Sie werden sehr bald eine SMS mit allen Details erhalten.",
            smsLine2: "Gehen Sie nachsehen!",
            whatsappLine1: "Überprüfen Sie Ihr Telefon",
            whatsappLine2: "Alle Informationen wurden über Whatsapp gesendet",
            mailLine1: "Sie werden in Kürze eine E-Mail mit allen Details erhalten.",
            mailLine2: "Entdecken Sie diese E-Mail!",
        },
        form1: {
            legal: ``,
        },
        choiceSurvey: {
            title:'',
                fillSurveyIncite: "Beantworten Sie erneut eine Umfrage und erhalten Sie einen 1€-E-Gutschein für Ihren nächsten Einkauf",
        },
        survey: {
            title: "Vielen Dank, dass Sie noch ein paar Fragen beantworten",
                about: "Umfrage",
        },
        confirmation: {
            title: "Bestätigung",
                textes: {
                p1: `Wir danken Ihnen für Ihre Teilnahme an der Aktion ${clientName}.`,
                    p2: "Sie erhalten Ihre Rückerstattung innerhalb von 6 Wochen, sofern Sie die allgemeinen Geschäftsbedingungen erfüllt haben.",
                    Coupon: "Wir haben Ihren E-Coupon an die angegebene E-Mail-Adresse geschickt. Viel Spaß damit!",
                    p3: "",
                    p4: `Das Western Digital Team`
            },
        },
        actionEnded: {
            text: "Bitte besuchen Sie unsere Website regelmäßig, um sich über zukünftige Aktionen zu informieren."
        },
        beforeStart: {
            text: "Sie können auf die Seite von ",
            shouldBeginSoon: "Die Aktion sollte bald beginnen. Bitte kommen Sie später wieder."
        },
    },


    popUp: {
        title: "Informations publicitaires pertinentes et publicités en ligne personnalisées",
        text: "Nous avons à cœur de rendre votre expérience chez nous et auprès des marques du groupe P&G intéressante, plaisante et personnalisée. Pour cela, nous nous efforçons d’élaborer nos communications commerciales en fonction de vos centres d’intérêt et évitons de vous envoyer des propositions qui ne correspondent pas à vos goûts ou de vous diffuser des publicités que vous avez déjà vues plusieurs fois. Comment procédons-nous ?",
        ul:{
            li1:"Regroupement des données pour mieux personnaliser nos communications en fonction de vos centres d’intérêt. En nous fondant sur notre intérêt légitime, nous sommes susceptibles d’enrichir vos données d’inscription et les données que vous nous divulguez lorsque vous utilisez nos services (marques préférées, bons de réductions épinglés, etc.) avec des attributs, des centres d’intérêt, des données démographiques obtenues auprès de sources commercialement disponibles ou de tiers. De même, si vous avez accepté nos cookies ou technologies similaires concernant les publicités ciblées sur les sites Web ou applications de P&G ou, dans certains cas, sur les sites Web ou applications de tiers, nous sommes susceptibles de compléter les données obtenues grâce à nos cookies relatifs aux publicités ciblées avec d’autres informations que vous nous avez communiquées au moment de la création d’un compte ou de l’utilisation de nos services.",
            li2:"Nous traiterons ces données, notamment par des moyens automatisés, pour personnaliser notre contenu et nos offres envoyés par e-mail, en ligne (par exemple, sur des plateformes de tiers, y compris les plateformes de réseaux sociaux) et hors ligne (par exemple, par courrier postal) si vous avez consenti à recevoir des communications commerciales de notre part.",
            li3:"Ciblage d’audiences similaires. Nous nous fondons sur notre intérêt légitime pour analyser vos données et identifier de nouveaux individus au profil similaire au vôtre et qui pourraient être intéressés par notre marque. Par exemple, nos sociétés affiliées P&G et nous sommes susceptibles d’envoyer votre adresse e-mail hachée sur Facebook et de demander à Facebook de proposer nos publicités à des individus dont les centres d’intérêt sont similaires aux vôtres, y compris dans d’autres pays, sur la base de données que Facebook détient à votre sujet et au sujet d’autres individus.",
            li4:"Utilisation de vos données à caractère personnel pour vous contacter sur des médias en ligne. Si vous avez consenti à la réception de communications commerciales de notre part, nous utiliserons vos données à caractère personnel (c’est-à-dire, votre adresse e-mail, votre numéro de téléphone, ou votre ID publicitaire mobile) hachées pour vous envoyer des publicités sur des canaux en ligne (par exemple, sur votre compte de réseau social). Pour cela, nous passons par un processus qui respecte la confidentialité de vos données en utilisant des données chiffrées et nous faisons appel à des partenaires de choix. Plus particulièrement, nous partagerons vos données à caractère personnel avec des tiers qui nous proposent des services de publicités personnalisées, y compris des plateformes de réseaux sociaux. Nous avons recours à ce genre de services pour associer les informations hachées que nous détenons avec les données à caractère personnel contenues dans leurs bases de données pour créer des audiences personnalisées et adapter les publicités à vos centres d’intérêt sur Internet, y compris les réseaux sociaux, comme l’autorise la loi.",
            li5:"Cela nous permet de vous envoyer des publicités en ligne plus pertinentes (offres, informations sur les produits et innovations) et de vous envoyer moins de publicités, étant donné que nous savons quand nous vous avons déjà envoyé une publicité et que nous pouvons vous exclure des communications suivantes sur le même sujet. Nos clients apprécient généralement que nous ne leur partagions pas de publicités sur des produits qui ne les intéressent pas ou qu’ils ont déjà vues plusieurs fois, mais si ce n’est pas le cas, vous pouvez toujours modifier vos préférences.",
            li6:"Vous pouvez toujours changer d’avis et retirer votre consentement/refuser l’utilisation de vos données pour l’envoi de communications et publicités personnalisées, y compris la création d’audiences personnalisées et similaires, ",
            li6Link: "https://preferencecenter.pg.com/fr-be/datarequests/",
            li6LinkLabel: "ici",
            li6Part2: ".",
            li6Link2: "https://privacypolicy.pg.com/fr-BE/",
            li6LinkLabel2: "Lire notre politique de confidentialité.",
        }
    },

    popUp2: {
        title: "Votre confidentialité nous importe",
        ul:{
            li1:"Notre politique de confidentialité régit notre collecte et notre utilisation de vos données",
            li2:"C'est",
            li2Link: "https://privacypolicy.pg.com/fr-BE/",
            li2LinkLabel:"pourquoi",
            li2Part2:"nous collectons vos données, nos bases juridiques pour le traitement, et la période pendant laquelle vos données personnelles seront conservées.",
            li3:"Nos e-mails contiennent des pixels qui aident P&G à comprendre si vous interagissez avec eux et quel contenu vous intéresse. En savoir plus",
            li3Link:"https://privacypolicy.pg.com/fr-BE/",
            li3LinkLabel:"ici",
            li3Link2:"https://preferencecenter.pg.com/fr-be/datarequests/",
            li3LinkLabel2:"désinscrire",
            li3Part2:". Vous pouvez vous",
            li3Part3:"de nos programmes de communication par e-mail à tout moment.",
            li4:"Vos informations personnelles seront traitées vers un pays différent de celui où elles ont été recueillies, notamment aux États-Unis. En savoir plus",
            li4Link:"https://privacypolicy.pg.com/fr-BE/",
            li4LinkLabel:"ici",
            li4Part2:".",
            li5:"Vous pouvez exercer vos droits de détenteur de données (vous pouvez accéder, corriger, effacer, transférer à un autre prestataire, refuser ou restreindre notre traitement de vos données personnelles, ou retirer votre consentement) ou changer vos préférences et abonnements en cliquant",
            li5Link:"https://preferencecenter.pg.com/fr-be/datarequests/",
            li5LinkLabel:"ici",
            li5Part2:".",
        }
    },

    PGLegal: {
        title: "Pourquoi collectons-nous ces données ?",
        mainText:{
            personalData: "Procter&Gamble, le responsable de traitement, traitera vos données à caractère personnel pour vous permettre de vous inscrire sur le présent site et d’utiliser ses services, et, si vous y consentez, pour vous envoyer des communications commerciales, y compris des publicités personnalisées sur les médias en ligne.",
            moreInfoLinkLabel: "En savoir plus",
            privacyInfoText: "Pour de plus amples informations concernant le traitement de vos données et vos droits en matière de confidentialité, veuillez vous reporter à",
            privacyLinkLabel: "cette section",
            privacyInfoText2: "ou consulter notre",
            privacyPolicyLinkLabel: "Politique de confidentialité",
            privacyLink: "https://privacypolicy.pg.com/fr-BE/",
            privacyInfoText3: " intégrale.",
        }
    },

    counterText: {
        participationLeft : {
            before: "Noch",
            after: "verfügbare Beteiligungen",
        },
        daysLeft: {
            before: "Sie haben noch",
            after: "Tage Zeit, um teilzunehmen !"
        }
    },




    inputLabel: {
        defaultListHelper: "Wählen Sie einen Vorschlag aus der Liste",
        mandatoryFields: "Pflichtfelder",
        noResult: "Kein Ergebnis",





        empty : {
            label: "",
            placeholder: "",
            helper: ""
        },
        cnk1 : {
            label: "Wie lautet der CNK-Code des Produkts Clearblue ?",
            placeholder: "0000-000",
            helper: "Das Format ist: XXXX-XXXX",
            error: "Der CNK-Code ist nicht korrekt",
        },



        // https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation



        /*
                typePassword : "Indiquez votre mot de passe",
                helpPassword : "Votre mot de passe doit contenir X, Y et Z",
                typeUniqueCode : "Indiquez ici le code visible sur le ticket de caisse",
                */


        upload: {
            state: "Status",
            uploadYourFile: "Senden Sie Ihre Dateien",
            clickOrDrop: "Klicken oder legen Sie Ihr Ticket hier ab.",
            rules: "Das Bild muss eine Datei vom Typ: JPG, JPEG, PNG, BMP, GIF, SVG, WEBP, PDF sein",
            remove: "Entfernen",
            waiting: "Wartet auf den Versand...",
            uploaded: "Gesendet!",
            error: "Gescheitert",
            uploadsDone: "Upload erfolgreich",
            onlyImages: "Es werden nur Bilder akzeptiert.",
            maxSize: "Maximale Größe von 20 MB.",
            uploadFile: "Datei hochladen",
            generalError: "Fehler, bitte versuchen Sie es später noch einmal"
        }
    },


}
