<template>
	<div class="aCustomInput field">
		<label  :for="fieldname">{{label}}<span v-if="isRequired" class="req">&nbsp;*</span></label>
		<input
		:id="fieldname"
		:type="inputType"
		v-model="modelValueComputed"
		:placeholder="placeholder"
		:disabled="isDisabled"
		:class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
		tabindex="0"
		>
		<div class="errorMessage" v-if="isInError"><ul v-html="errorMessage"></ul></div>
		<div class="helper" v-html="helper"></div>
	</div>
</template>

<!-- ========================================= -->

<script>
// Load only a part of lodash to keep the application small
	import { debounce } from "lodash";
	import FormFields from "../mixins/FormFields";
	import dayjs from "dayjs";
	import config from "../config";
	var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
	dayjs.extend(isSameOrBefore)
	export default {
		name: "customInput",

		emit: ["update:modelValue", "update:valueHasChanged", "runCheck", "maintenanceDetected"],

		mixins: [FormFields],

		props: {
			inputType:{
				type: String,
				validator : function(value){
					return ["text","password","email","hidden","number","search","tel","url", "date"].includes(value);
				}
			},
			label : {
				type: String,
				required : true
			},
			isDisabled : {
				type: Boolean,
				required: false,
			default: false
			},
			valueHasChanged: {
				type: String,
				required: false
			},
		},

		data(){
			return {
				errorMessage : "",
				config: config,
			}
		},

		computed: {
			modelValueComputed: {
				get() {
					return this.modelValue;
				},
				set(newVal) {
					this.validateMyselfDebounced();
					this.$emit('update:modelValue', newVal);
				}
			}
		},

		methods : {
			validateMyself() {
				if(this.inputType === "date") {
					return this.dateCheck()
				}
				this.setLoading();

				console.group("CustomInput Validation " + this.fieldname);
				console.log(`I use ${this.fieldname} as key and ${this.modelValueComputed} as value`);
				console.groupEnd();

				let data = {
					[this.fieldname]: this.modelValueComputed
				};

				this.axios.post(
					`/lead/${config.idlead}/data/${this.fieldname}/validate`,
					data
					)
				.then(res => {
					console.log("I got a positive CustomInput result: ");
					console.log(res);
					// A non-required field must be neutral if empty
		  			/* INFO I had to use this.modelValue.Computed.toString().length > 0 with a quantity (as a number, length was undefined).  */
					if(this.modelValueComputed.length > 0){
						this.giveSuccess();
						this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
					}else{
						this.removeSuccessOrError();
					}  
					this.setNotLoading();
				})
				.catch(error => {
					console.log(`CustomInput reported an error: ${error.response.data.message}`);
					if(error.response.status === 503){
						this.setNotLoading();
						this.$emit('maintenanceDetected');
					}else{
			  			// Error managing. Get Values, whatever keys are
						this.errorMessage = this.$t('errors.unmanaged');
						try{
							if(error.response.data.errors){
								let combinedList = Object.values(error.response.data.errors).flat();
								console.log("Combined list of errors :");
								console.log(combinedList);
								if(this.modelValueComputed.length > 0) {
									this.errorMessage = this.$t('inputsTexts.errors.error_incorrect');
									if(this.fieldname === "email"){
										this.errorMessage = this.$t('inputsTexts.errors.error_email');
									}
									if(this.fieldname === "IBAN"){
										this.errorMessage = this.$t('inputsTexts.errors.error_iban');
									}
								}else{
									this.errorMessage = this.$t('inputsTexts.errors.mandatory');
								}
							}
						}catch(e){
							console.log("An error occured with the error response received from Action-API");
							console.log(`In the field: ${this.fieldname}`);
						}
						this.giveError();
						this.setNotLoading();
						this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
					}

				})
				.then(()=>{});
			},

			createAnHtmlListFromErrors(errorsList){
				let html = "";
				errorsList.forEach(err => {
					html += `<li>${err}</li>`;
				});
				return html;
			},

			/* TODO : add a check: is date inbetween purchase dates from lead */
			/* ! THERE IS A PROBLEM : 2022/12/05 and 2022/05/12 are not the same ! */
			/* use then this.$t('errors.dateOutOfPeriod'); */
			dateCheck() {
				this.dateMessage = null;
				let date = dayjs(this.modelValueComputed);
				if(!date.isValid()) {
					this.errorMessage = this.$t('errors.dateInvalid');
					this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
				} else if(!date.isSameOrBefore(dayjs())) {
					this.errorMessage = this.$t('errors.dateBefore');
					this.giveError();
					this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
				} else {
					this.giveSuccess();
					this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
				}
			},
		},

		watch: {
			modelValue(){
				this.validateMyselfDebounced();
			},
		},

		created() {
			this.validateMyselfDebounced = debounce(this.validateMyself, 500);
	// run validation immediately when the value is not null or empty
			if(this.modelValueComputed) {
				this.validateMyselfDebounced();
			}
		}
	}

</script>

<!-- ========================================= -->

<style lang="scss" scoped>
</style>
