<template>
	<div class="input-group">
		<label class="form-label" for="googleInput">{{ labelName }}</label>
		<div class="inputs">
			<input
			:readonly="readOnly"
			:class="{ 'is-invalid': isWrong }"
			:type="inputType"
			:placeholder="placeholderName"
			:value="modelValue"
			@input="$emit('update:modelValue', $event.target.value)"
			class="form-control"
			id="googleInput"
			:aria-label="$t('inputsTexts.googleAPI.ariaLabel')"
			/>

			<button
			@click="clearFilter"
			class="btn"
			type="button"
			id="button-addon2"
			title="clear-adress-button"
			>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50" height="50" overflow="visible" stroke="black" stroke-width="5" stroke-linecap="round">
				<line x1="10" y1="10" x2="40" y2="40" />
				<line x1="40" y1="10" x2="10" y2="40" />
			</svg>
		</button></div>
		

		<div v-if="errors.length > 0">
			<p class="txt-red" v-for="(error, index) in errors" :key="index">
				{{ error }}
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'GoogleInput',
		props: {
			value: {
				required: false
			},
			labelName: {
				type: String,
				required: false,
			default: ''
			},
			placeholderName: {
				type: String,
				required: false,
			default: ''
			},
			ariaLabel: {
				type: String,
				required: false,
				default: ''
			},
			inputType: {
				type: String,
				required: true,
			default: 'text'
			},
			readOnly: {
				type: Boolean,
			default: false
			},
			modelValue: {
				required: false
			}
		},

		emits: ['update:modelValue'],

		data: () => {
			return {
				isWrong: false,
				errors: []
			}
		},

		computed: {
			result: {
				get: function () {
					return this.value
				},
				set: function (value) {
					this.$emit('input', value)
				}
			}
		},

		methods: {
			clearFilter () {
				if (this.result) {
					this.result = ''
				}
				this.$emit('update:modelValue', '');
			}
		}
	}
</script>

<style scoped>
	.is-invalid {
		border: 1px solid red;
	}

	.txt-red {
		color: red;
	}
</style>
