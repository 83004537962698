<template>
	<div class="breadcrumbs" :class="['steps'+steps.length]" aria-label="Fil d'ariane / breadcrumb">
		<template v-for="(item, index) in steps" :key="index">
			<svg class="arrow" width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg" v-if="index != 0" aria-hidden="true">
				<path d="M1,10c-.2559,0-.5117-.0977-.707-.293-.3906-.3906-.3906-1.0234,0-1.4141l3.2372-3.2372c.0308-.0308,.0308-.0808,0-.1116L.293,1.707C-.0977,1.3164-.0977,.6836,.293,.293S1.3164-.0977,1.707,.293l3.9442,3.9442c.4213,.4213,.4213,1.1043,0,1.5256l-3.9442,3.9442c-.1953,.1953-.4512,.293-.707,.293Z"/>
			</svg>
			<crumbs :step-name="item" :step-number="index" :is-valid="index < currentStep"></crumbs>
		</template>
	</div>
</template>

<!-- ================================================================================== -->

<script>
	import Crumbs from '@/components/Crumbs.vue';

	export default {
		name:"BreadCrumbs",

		components: {
			Crumbs,
		},

		props: {
			steps: {
				type: Array,
				required: true
			},
			currentStep: {
				type: Number,
				required: true
			}
		},
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
	$small_max : 559px;
	$medium_min: 560px;
	$medium_max: 859px;
	$big_min: 860px;

	.breadcrumbs {
		display: grid;
		width: 100%;
		max-width: 744+188px; // max for 5 steps

		@media screen and (max-width : $small_max) {
			margin: 0 auto;
		}

		@media screen and (min-width : $medium_min) {
			margin: 0 auto;
			text-align: center;
		}

		@media screen and (max-width : 1024px) {
			align-items: center;
			grid-template-columns: 1fr 1fr;
			letter-spacing: 0;
		}
		
		@media screen and (min-width : $big_min) {
			margin: 40px auto;
			justify-content: center;

			// structure depends on number of steps in the breadcrumb.
			&.steps1 {
				grid-template-columns: 180px;
			}
			&.steps2 {
				grid-template-columns: 180px 8px 180px;
			}
			&.steps3 {
				grid-template-columns: 180px 8px 180px 8px 180px;
			}
			&.steps4 {
				grid-template-columns: 180px 8px 180px 8px 180px 8px 180px;
			}
			&.steps5 {
				grid-template-columns: 180px 8px 180px 8px 180px 8px 180px 8px 180px;
			}
			&.steps6 {
				grid-template-columns: 180px 8px 180px 8px 180px 8px 180px 8px 180px 8px 180px;
			}
			/* NOT RECOMMANDED */
			&.steps7 {
				grid-template-columns: 160px 8px 160px 8px 160px 8px 160px 8px 160px 8px 160px 8px 160px;
			}
		}

		@media screen and (min-width: $big_min) and (max-width: 1070px){
			transform: scale(0.8);
		}


	}

	.arrow {
		height:10px;
		width:8px;
		margin-top:40px;
		@media screen and (max-width : $medium_max) {
			display: none;
		}
	}


</style>
